<template>
    <CCard class="pb-2"> 
         <CCardHeader class="pt-2 pb-0">  
            <div class="float-left d-flex flex-row mt-1 mb-1" style="width:auto">
                <CIcon name="cil-wallpaper" size="lg" class="mr-2" /><h5 class="">Design &amp; Customization</h5>     
            </div>        
            <CSpinner v-if="custom && loading" size="sm" class="ml-2 mt-2" color="info" />   
        </CCardHeader>
        <CCardBody class="pt-2 pb-2">
            <CRow v-if='loading && !custom'>  
                <CCol lg="12" class="justify-content-center mt-1">
                    <CSpinner />
                </CCol>
            </CRow> 
            <template v-if="custom">
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Favicon</label>        
                <div class="ml-1">
                    <img v-if="hasFavicon" class="favicon mt-2" 
                        :src="favicon" 
                        @error="faviconMissing($event)"
                    />
                    <CBadge v-else color="secondary" class="mt-2">Missing</CBadge>
                    <ContentUploader v-if="canEdit" fileType="favicon" class="ml-2 my-0" @uploaded="uploaded" />
                </div>
            </div>     
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Custom Stylesheet</label>        
                <a v-if="custom" download :href="custom.stylesheetUrl" target="_blank" class="col-form-label text-info">{{ custom.stylesheet }}</a>
                <ContentUploader v-if="canEdit" fileType="css" class="ml-2 my-1" @uploaded="uploaded" />
            </div>   
            <hr class="mt-1 mb-0"/>
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Logo Image</label>      
                <div class="col-form-label col">
                    <a v-if="custom.logoImage" :href="custom.logoImageUrl" download class="col-form-label text-info" target="_blank">{{ custom.logoImage }}</a>                        
                    <CBadge v-else color="secondary" class="mt-1">Missing</CBadge>
                    <ContentUploader v-if="canEdit" fileType="logo" class="ml-2" @uploaded="uploaded" />
                    <br />                
                    <img v-if="custom.logoImageUrl" class="favicon mt-1" style="max-height: 50px;"
                        :src="custom.logoImageUrl" 
                    />                                        
                </div>
            </div>
            <hr class="mt-1 mb-0"/>
            <div class="form-group form-row mb-0">               
                <label class="col-form-label col-sm-1 strong label-col">Hero Image</label>        
                <div class="col-form-label col">
                    <a v-if="custom.heroImage" :href="custom.heroImageUrl" download class="col-form-label text-info" target="_blank">{{ custom.heroImage }}</a>
                    <CBadge v-else color="secondary" class="mt-1">Missing</CBadge>
                    <ContentUploader v-if="canEdit" fileType="hero" class="ml-2" @uploaded="uploaded" />
                    <br />
                    <img v-if="custom.heroImageUrl" class="favicon mt-1" style="max-height: 200px;" 
                        :src="custom.heroImageUrl" 
                    />                    
                </div>                
            </div>               
            </template>
        </CCardBody>
    </CCard>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/general/actions';
import { EDIT_CONTENT, mapPermissions } from '@/permissions';
import ContentUploader from '@/components/ContentUploader.vue';

export default {
    components: {
        ContentUploader
    },
    computed: {
        ...mapGetters({
            loading: 'general/loading',
            custom: 'general/custom'
        }),
        ...mapPermissions({
            canEdit: EDIT_CONTENT
        }),
        favicon() {
            return (this.custom && this.custom.favicon) ? `${this.custom.favicon}?${this.faviconCounter}`  : null;
        }
    },
    data() {
        return {
            faviconCounter: 0,
            hasFavicon: true
        }
    },
    methods: {
        ...mapActions({
            load: actionNames.LOAD
        }),
        faviconMissing() {
            this.hasFavicon = false;
        },
        uploaded() {
            this.faviconCounter++;
            this.load();  
        }
    },
    mounted() {
        this.load();        
    }
}
</script>

<style scoped>
    .label-col {
        min-width: 150px;
    }
    .favicon {
        border: solid 1px #ddd;
    }
</style>
