<template>
    <vue-dropzone 
        class="dropzone content-drop"
        style="display: inline-block !important; max-height: 25px !important; "
        :id="getId()"
        ref="dropzone"
        :useCustomSlot="true"
        :include-styling="true"
        :options="dropzoneOptions"                 
        @vdropzone-file-added="fileAdded"        
        @vdropzone-sending="sending"
        @vdropzone-error="fileError"
        @vdropzone-success="completed"
        vdropzone-canceled="cancelled"
        @vdropzone-queue-complete="queueCompleted"
        @vdropzone-max-files-exceeded="maxFilesExceeded"
    ><!-- @vdropzone-thumbnail="thumbnailGenerated" -->
        <CBadge class="upload m-1" :color="uploading ? 'secondary': 'dark'">Upload</CBadge>
        <CSpinner v-if="uploading" size="sm" class="mx-1" color="secondary" />
    </vue-dropzone>  
</template>

<script>
/* eslint-disable */
import vue2Dropzone from 'vue2-dropzone';
import { mapGetters, mapActions } from 'vuex';
import { REFRESH } from '@/store/modules/auth/action-types';
import { PUSH_ERROR, PUSH_SUCCESS } from '@/store'
import { getContentUploadUrl } from '@/api';

export default {
    props: {
        fileType: String,
    },
    components: {
        vueDropzone: vue2Dropzone,        
    },
    data() {
        return {
            refId: null,
            uploading: false,
            // Dropzone Options:
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                withCredentials: true,
                createImageThumbnails: false,
                autoProcessQueue: false,
                addRemoveLinks: true,
                thumbnailWidth: 100,
                thumbnailHeight: 100,
                thumbnailMethod: "contain",
                acceptedFiles:'image/*,.css',
                maxFiles: 1,
                parallelUploads: 1, 
                uploadMultiple: false,
            }
        }
    },
    computed: {
        ...mapGetters({
            merchantId: 'merchantId',
            token: 'token',
            tokenExpiration: 'tokenExpiration'
        })
    },
    methods: {
        ...mapActions({refresh: REFRESH}),
        getId() {
            if (!this.refId) {
                this.refId = "dropzone" + Math.floor(Math.random() * 100);
            }
            return this.refId;
        },
        init() {
            const url = getContentUploadUrl(this.merchantId, this.fileType);
            this.dropzoneOptions.url = getContentUploadUrl(this.merchantId, this.fileType);
            this.$refs.dropzone.setOption('url', this.dropzoneOptions.url);
        },
        async thumbnailGenerated(file, dataUrl) {
            const zone = this.$refs.dropzone;
            if (!this.tokenExpiration || (this.tokenExpiration <= (new Date().getTime() + 1500))) {
                try {
                    await this.refresh();
                }
                catch(e){
                    this.$store.commit(PUSH_ERROR, {message: `An error occured. Please login again`});
                    return;
                }
            }
            zone.processQueue();
        },
        async fileAdded(file) {            
            const zone = this.$refs.dropzone;            

            if (this.uploading) {
                return false;   
            }

            if (!this.tokenExpiration || (this.tokenExpiration <= (new Date().getTime() + 1500))) {
                try {
                    await this.refresh();
                }
                catch(e){
                    this.$store.commit(PUSH_ERROR, {message: `An error occured. Please login again`});
                    return;
                }
            }
            setTimeout(function() {
                zone.processQueue();
            }, 10);
            
        },
        maxFilesExceeded(file) {
            const zone = this.$refs.dropzone;
            zone.removeAllFiles(true);
        },
        sending(file, xhr, formData) {     
            this.uploading = true;
            xhr.setRequestHeader('Authorization', `bearer ${this.token}`);
        },
        queueCompleted() {
            const zone = this.$refs.dropzone;
            zone.removeAllFiles();
        },
        completed(file, response) {    
            this.uploading = false;   

            const zone = this.$refs.dropzone;
            zone.removeAllFiles();
            this.$store.commit(PUSH_SUCCESS, {message: "Upload successful"});       
            
            this.$emit('uploaded')
        },
        cancelled() {
            this.uploading = false;   

            const zone = this.$refs.dropzone;
            zone.removeAllFiles();
        },
        fileError(file,message,xhr) {        
            this.uploading = false;  

            const zone = this.$refs.dropzone;
            zone.removeAllFiles(true);

            if (file && !xhr) {                
                this.$store.commit(PUSH_ERROR, {message});
            }
            else {
                this.$store.commit(PUSH_ERROR, {message: "Upload failed"});
            }
        },          
    },
    mounted(){
        this.init();
    },
}
</script>

<style>
.content-drop {
    border: dashed 1px #888; max-height: 25px !important;
}
.content-drop .dz-preview {
    display: none;
    visibility: collapse;
}
</style>